import React from 'react';
import '../styles/NotFound.css';
import {Helmet} from "react-helmet";


const NotFound = () => {
    return (
      <section className="page_404">
          <Helmet>
              <title>404</title> {/* Définir le titre de la page */}
          </Helmet>
          <link rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/3.3.7/css/bootstrap.min.css"/>
          <link rel="canonical" href="https://codepen.io/Navedkhan012/pen/vrWQMY"/>
          <div className="container">
              <div className="row">
                  <div className="col-sm-12 ">
                      <div className="col-sm-10 col-sm-offset-1 text-center">
                          <div className="four_zero_four_bg">
                              <h1 className="text-center">404</h1>
                          </div>
                          <div className="contant_box_404">
                              <h3 className="h2">Look like you're lost</h3>
                              <p>The page you are looking for is not available!</p>
                              <a href="/" className="link_404">Go to Home</a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
  );
};

export default NotFound;
