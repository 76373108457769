import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Footer.css'; // Assurez-vous de créer un fichier CSS pour styliser le footer
import logo from '../assets/my logo.png'; // Remplacez par le chemin correct de votre logo

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="logo-container">
                    <Link to="/" className="footer-link"><img src={logo} alt="Promete-IT Logo" className="footer-logo"/></Link>
                </div>
                <div className="footer-links">
                    <Link to="/" className="footer-link">Accueil</Link>
                    <Link to="/about" className="footer-link">À propos</Link>
                    <Link to="/mentions-legales" className="footer-link">Mentions Légales</Link>
                    <p>Développé par <strong>Houssam Laghzil</strong> Fondateur de promete-it</p>
                    {/*<Link to="/services" className="footer-link">Services</Link>
                    <Link to="/contact" className="footer-link">Contact</Link>*/}
                </div>
            </div>
        </footer>
    );
}

export default Footer;
