// src/firebaseConfig.js

import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
import {getAnalytics, setAnalyticsCollectionEnabled} from "firebase/analytics";
import {getDatabase} from "firebase/database";

// Configuration Firebase
const firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DATABASEURL,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// Initialiser Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const database = getDatabase(app);

// Désactiver la collecte jusqu'à ce que l'utilisateur accepte les cookies
setAnalyticsCollectionEnabled(analytics, false);

// Exporter les modules nécessaires
export { app, auth, analytics, database, setAnalyticsCollectionEnabled };
