import React, { useState, useEffect } from 'react';

const CookieBanner = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Vérifier l'existence du cookie
        const cookieConsent = document.cookie.split('; ').find(row => row.startsWith('userConsent='));
        if (!cookieConsent) {
            setIsVisible(true); // Afficher le bandeau si le cookie n'existe pas
        }
    }, []);

    const handleAccept = () => {
        // Gérer l'acceptation des cookies
        document.cookie = "userConsent=accepted; path=/; max-age=5184000"; // expire après 60 jours
        setIsVisible(false);
        console.log("Consentement des cookies accepté");
    };

    const handleDecline = () => {
        // Gérer le refus des cookies
        document.cookie = "userConsent=declined; path=/; max-age=5184000"; // expire après 60 jours
        setIsVisible(false);
        console.log("Consentement des cookies refusé");
    };

    if (!isVisible) {
        return null; // Ne pas afficher le bandeau si isVisible est false
    }

    return (
        <div style={styles.banner}>
            <div style={styles.message}>
                Ce site utilise des cookies pour améliorer l'expérience utilisateur. En utilisant notre
                site, vous acceptez l'utilisation de tous les cookies conformément à notre Politique de Cookies.
            </div>
            <div style={styles.buttons}>
                <button onClick={handleAccept} style={styles.acceptButton}>J'accepte</button>
                <button onClick={handleDecline} style={styles.declineButton}>Je refuse</button>
            </div>
        </div>
    );
};

const styles = {
    banner: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        color: '#fff',
        display: 'flex',
        backgroundColor: 'black',
        padding: '15px',
        borderRadius: '15px 15px 0px 0px',
        flexDirection: 'row',
        flexWrap: 'wrap',
        zIndex: 1000,
    },
    message: {
        flex: '1 0 300px',
        margin: '15px',
    },
    buttons: {
        display: 'flex',
        gap: '10px',
        margin: '15px',
    },
    acceptButton: {
        backgroundColor: '#ffd42d',
        border: 'none',
        padding: '10px 20px',
        cursor: 'pointer',
        color: '#083260',
    },
    declineButton: {
        backgroundColor: '#f00',
        border: 'none',
        padding: '10px 20px',
        cursor: 'pointer',
        color: '#fff',
    },
};

export default CookieBanner;