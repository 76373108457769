import React, {useEffect, useState} from 'react';
import '../styles/SplashScreen.css';

const SplashScreen = () => {
    const [snowflakes, setSnowflakes] = useState([]);

    useEffect(() => {
        const snowflakeCount = 500;
        const newSnowflakes = [];
        for (let i = 0; i < snowflakeCount; i++) {
            const size = Math.random() * 10 + 10;
            const duration = Math.random() * 1.1 + 5;
            const delay = Math.random() * 5;
            const drift = Math.random() * 100 - 50;

            const style = {
                left: `${Math.random() * 100}vw`,
                top: `-${size}px`,
                fontSize: `${size}px`,
                animationDuration: `${duration}s`,
                animationDelay: `${delay}s`,
                '--drift': `${drift}px`,
            };

            newSnowflakes.push(
                <div key={i} className="snowflake" style={style}>
                    ❄️
                </div>
            );
        }
        setSnowflakes(newSnowflakes);
    }, []);

    return (
        <div className="splash-screen">
            {snowflakes}
            <img src="/my logo.ico" alt="Logo" className="splash-icon" />
            <h1>🎅❄️ Ho ho ho ❄️🎅</h1>
            <p>Chargement en cours...</p>
        </div>
    );
};

export default SplashScreen;
