import React from 'react';
import '../styles/LegalMentions.css';
import {Helmet} from "react-helmet";

function LegalMentions() {
    return (
        <div className="container">
            <Helmet>
                <title>Mentions Légales - Promete-it | Informations et Responsabilités</title>
                <meta name="description" content="Découvrez les mentions légales de Promete-it. Informations légales, limitation de responsabilité, protection des données et conditions d'utilisation." />
                <meta name="keywords" content="mentions légales, Promete-it, informations légales, responsabilité, protection des données, conditions d'utilisation" />
                <meta property="og:title" content="Mentions Légales - Promete-it | Informations et Responsabilités" />
                <meta property="og:description" content="Mentions légales de Promete-it, avec des informations sur la responsabilité, les conditions d'utilisation et la protection des données." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://promete-it.fr/legal-mentions" />
                <meta name="robots" content="index, follow" />
            </Helmet>

            <h2>Mentions Légales</h2>

            <section>
                <h3>1. Informations légales</h3>
                <p>
                    <strong>Nom de l'entreprise :</strong> Promete-IT<br/>
                    <strong>Responsable de publication :</strong> Laghzil Houssam<br/>
                    <strong>Contact :</strong> contact@promete-it.fr
                </p>
            </section>

            <section>
                <h3>2. Limitation de responsabilité</h3>
                <p>
                    Promete-IT s'engage à fournir des services de développement web de qualité. Toutefois, en raison de
                    la nature complexe et évolutive du domaine numérique, Promete-IT décline toute responsabilité dans
                    les cas suivants :
                </p>
                <ul className={"ul-ML"}>
                    <li><strong>Problèmes liés aux serveurs :</strong> Promete-IT n'est pas responsable des
                        interruptions de service, des pertes de données ou de tout autre problème lié à des serveurs qui
                        ne sont pas sous son contrôle direct.
                    </li>
                    <li><strong>Cybersécurité :</strong> Promete-IT ne peut être tenu responsable en cas de
                        cyberattaques, de piratages, ou d'intrusions non autorisées. Toute tentative d'intrusion sans
                        autorisation écrite est strictement interdite.
                    </li>
                    <li><strong>Performance des sites web :</strong> Promete-IT ne garantit pas un certain niveau de
                        trafic ou d'attraction de clients pour les sites web développés. La performance dépend de
                        nombreux facteurs externes.
                    </li>
                </ul>
            </section>

            <section>
                <h3>3. Force majeure</h3>
                <p>
                    Promete-IT ne pourra être tenu responsable de tout retard ou manquement dans l'exécution de ses
                    obligations si ces retards ou manquements résultent d'un cas de force majeure tel que défini par la
                    loi.
                </p>
            </section>

            <section>
                <h3>4. Bugs et erreurs techniques</h3>
                <p>
                    Promete-IT s'engage à corriger les bugs signalés par le client dans les meilleurs délais. Toutefois,
                    Promete-IT ne peut être tenu responsable des bugs ou problèmes techniques qui échappent à son
                    contrôle.
                </p>
            </section>

            <section>
                <h3>5. Propriété intellectuelle</h3>
                <p>
                    Tous les éléments graphiques, textuels et logiciels développés par Promete-IT restent la propriété
                    de Promete-IT jusqu'au paiement intégral des prestations.
                </p>
            </section>

            <section>
                <h3>6. Protection des données personnelles</h3>
                <p>
                    Promete-IT s'engage à respecter la confidentialité des données personnelles collectées. Aucune
                    donnée ne sera divulguée à des tiers sans consentement explicite.
                </p>
            </section>

            <section>
                <h3>7. Modifications des mentions légales</h3>
                <p>
                    Promete-IT se réserve le droit de modifier les présentes mentions légales à tout moment. Les
                    modifications prendront effet dès leur publication sur notre site internet.
                </p>
            </section>

            <section>
                <h3>8. Loi applicable</h3>
                <p>
                    Les présentes mentions légales sont régies par la loi française. En cas de litige, les tribunaux
                    compétents seront ceux du ressort du siège social de Promete-IT.
                </p>
            </section>

            <section>
                <h3>9. Contact</h3>
                <p>
                    Pour toute question concernant ces mentions légales, vous pouvez nous contacter à l'adresse suivante
                    : contact@promete-it.fr.
                </p>
            </section>
        </div>
    );
}

export default LegalMentions;
