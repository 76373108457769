// src/utils/carbonTracker.js

import {deleteCookie, getCookie, setCookie} from '../cookieUtils';
import {database} from '../firebaseConfig';
import {ref, runTransaction} from "firebase/database";

// Définissez le facteur d'émission pour calculer l'empreinte carbone
const emissionFactor = 0.81; // grammes de CO₂ par Mo

// Variable pour accumuler l'émission carbone totale
let totalCarbonEmission = 0;

// Fonction pour calculer l'empreinte carbone basée sur les octets transférés
function calculateCarbonEmission(bytes) {
    const megaBytes = bytes / (1024 * 1024);
    const emission = megaBytes * emissionFactor;
    console.log(`Empreinte carbone calculée pour ${bytes} octets: ${emission.toFixed(4)} grammes de CO₂`);
    return emission;
}

// Fonction pour envoyer les données d'empreinte carbone à Firebase
async function sendCarbonDataToFirebase(emission) {
    try {
        const userId = getUserId(); // Fonction pour obtenir l'userId depuis les cookies
        const dbRef = ref(database, 'carbonEmissions/' + userId);

        // Utilisez une transaction pour ajouter l'émission à la valeur existante
        await runTransaction(dbRef, (currentValue) => {
            return (currentValue || 0) + emission;
        });

        console.log(`Données d'empreinte carbone envoyées à Firebase avec succès: ${emission.toFixed(4)} grammes de CO₂`);
    } catch (error) {
        console.error('Erreur lors de l\'envoi des données d\'empreinte carbone à Firebase:', error);
        // Stocker l'émission dans les cookies si l'envoi a échoué
        setCookie('unsentCarbonEmission', emission, { sameSite: 'Strict', secure: true });
    }
}

// Fonction pour envoyer les données d'empreinte carbone
function sendCarbonData(emission) {
    sendCarbonDataToFirebase(emission);
}

// Redéfinir window.fetch pour intercepter les requêtes réseau
const originalFetch = window.fetch;
window.fetch = async function(...args) {
    try {
        const response = await originalFetch.apply(this, args);

        // Cloner la réponse pour pouvoir lire son corps
        const clonedResponse = response.clone();

        // Obtenir le content-length depuis les en-têtes
        const contentLength = clonedResponse.headers.get('content-length');

        let bytes = 0;
        if (contentLength) {
            bytes = parseInt(contentLength);
        } else {
            // Si content-length n'est pas disponible, estimer à partir du corps de la réponse
            const blob = await clonedResponse.blob();
            bytes = blob.size;
        }

        const emission = calculateCarbonEmission(bytes);
        totalCarbonEmission += emission;
        console.log(`Total des émissions de carbone mis à jour: ${totalCarbonEmission.toFixed(4)} grammes de CO₂`);

        return response;
    } catch (error) {
        console.error('Erreur dans la redéfinition de fetch:', error);
        return originalFetch.apply(this, args);
    }
};

// Redéfinir XMLHttpRequest pour intercepter les requêtes réseau
const OriginalXMLHttpRequest = window.XMLHttpRequest;
function CustomXMLHttpRequest() {
    const xhr = new OriginalXMLHttpRequest();

    xhr.addEventListener('loadend', function() {
        try {
            const responseText = xhr.responseText || '';
            const bytes = responseText.length * 2; // Estimation approximative : 2 octets par caractère
            const emission = calculateCarbonEmission(bytes);
            totalCarbonEmission += emission;
            console.log(`Total des émissions de carbone mis à jour: ${totalCarbonEmission.toFixed(4)} grammes de CO₂`);
        } catch (error) {
            console.error('Erreur lors du calcul de l\'émission depuis XMLHttpRequest:', error);
        }
    });

    return xhr;
}
window.XMLHttpRequest = CustomXMLHttpRequest;

// Fonction pour obtenir l'userId depuis les cookies ou en générer un nouveau
function getUserId() {
    let userId = getCookie('userId');
    if (!userId) {
        userId = generateUserId();
        setCookie('userId', userId, { sameSite: 'Strict', secure: true });
        console.log('Nouvel userId généré et stocké dans les cookies:', userId);
    } else {
        console.log('UserId existant trouvé dans les cookies:', userId);
    }
    return userId;
}

// Fonction pour générer un nouvel userId
function generateUserId() {
    return 'user_' + Math.random().toString(36).substr(2, 9);
}

// Écouteur d'événement pour beforeunload afin d'envoyer les données lorsque l'utilisateur ferme l'onglet ou le navigateur
window.addEventListener('beforeunload', function() {
    try {
        if (totalCarbonEmission > 0) {
            sendCarbonData(totalCarbonEmission);
            // Stocker dans les cookies au cas où l'envoi échoue
            setCookie('unsentCarbonEmission', totalCarbonEmission, { sameSite: 'Strict', secure: true });
            console.log('Données d\'empreinte carbone envoyées avant déchargement:', totalCarbonEmission.toFixed(4));
        }
    } catch (error) {
        console.error('Erreur dans l\'événement beforeunload:', error);
    }
});

// Au chargement de la page, vérifier s'il y a des données d'émission non envoyées dans les cookies
window.addEventListener('load', function() {
    try {
        const unsentEmission = getCookie('unsentCarbonEmission');
        if (unsentEmission) {
            sendCarbonData(parseFloat(unsentEmission));
            deleteCookie('unsentCarbonEmission');
            console.log('Données d\'empreinte carbone non envoyées envoyées depuis les cookies:', unsentEmission);
        }
    } catch (error) {
        console.error('Erreur lors de l\'envoi des données d\'empreinte carbone non envoyées depuis les cookies:', error);
    }
});
