import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Navbar.css';

const Navbar = () => {
    const [isHidden, setIsHidden] = useState(false);
    const lastScrollTop = useRef(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 715);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 715);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const scrollDown = scrollTop > lastScrollTop.current;

            if (scrollDown && scrollTop > 100) {
                setIsHidden(true);
            } else if (!scrollDown && scrollTop < 100) {
                setIsHidden(false);
            }

            lastScrollTop.current = scrollTop;
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const navStyle = {
        opacity: isHidden ? 0 : 1,
        position: 'fixed',
        left: '50%', // Centre horizontalement
        transform: `translateX(-50%) ${isHidden ? (isMobile ? 'translateY(100%)' : 'translateY(-100%)') : 'translateY(0%)'}`,
        transition: 'transform 0.5s ease, opacity 0.5s ease',
        width: 'auto',
        top: isMobile ? 'auto' : '0',
        bottom: isMobile ? '0' : 'auto',
        backgroundColor: 'var(--nav-bg-color)',
    };

    return (
        <nav style={navStyle}>
            <ul className="navbar-list">
                <li className="navbar-item"><Link className="navbar-link" to="/">Accueil</Link></li>
                <li className="navbar-item"><Link className="navbar-link" to="/about">À propos</Link></li>
            </ul>
        </nav>
    );
};

export default Navbar;
