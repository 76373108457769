// SoundPlayer.js
const listAudio = [
    "https://firebasestorage.googleapis.com/v0/b/promete-it.appspot.com/o/asset%2Fsound%2Fchristmas-logo-2-175231.mp3?alt=media&token=6975bd42-588c-4c08-a556-01c8b53bef96",
];
const randomIndex = Math.floor(Math.random() * listAudio.length);
const audiochois = listAudio[randomIndex]

class SoundPlayer {
    constructor() {
        this.sound = new Audio(audiochois);
        this.sound.volume = 0.5; // Réglez le volume à 50 %
    }

    playSound() {
        this.sound.currentTime = 0; // Remet le son au début
        this.sound.play().catch((error) => {
            console.warn('Playback failed:', error);
        });
    }
}

export default new SoundPlayer(); // Exportez une instance de SoundPlayer
